

let envprops = localStorage.envprops ? JSON.parse(localStorage.envprops) : {}
global.exam_table_fields = { "ip": { "name": "IP", "enable": true }, "dni": { "name": "DNI", "enable": true }, "key": { "name": "key", "enable": true }, "file": { "name": "Legajo", "enable": false }, "user": { "name": "Usuario", "enable": true }, "grade": { "name": "grade", "enable": false }, "examType": { "name": "Tipo de examen", "enable": false }, "userName": { "name": "Nombre y Apellido", "enable": true }, "countExam": { "name": "Sesiones", "enable": true }, "createdAt": { "name": "Fecha", "enable": true }, "faceVideo": { "name": "Video 2", "enable": true }, "validation": { "name": "Validación", "enable": true }, "subjectName": { "name": "Materia", "enable": true }, "desktopVideo": { "name": "Video 1", "enable": true }, "createdAtTime": { "name": "Inicio", "enable": true }, "finalizatedAt": { "name": "Fin", "enable": true }, "qualification": { "name": "Calificación", "enable": true } }
function tryset() {
    if (!envprops.url_back) {
        envprops = {}
        global.backurl = null
    }
    else {
        global.backurl = envprops.url_back.replace(envprops.url_back.slice(-5), "") + ""
        global.portRestBack = (envprops.url_back.slice(- 4)) + ""
        global.portIntegration = (envprops.url_integration || envprops.url_back).slice(- 4)
        global.loginType = (envprops.type_login) + ""
        global._lms_ = envprops.lms + ""
        global._uni_ = envprops.uni + ""
        global.env = envprops.env + ""
        global._uniName_ = envprops.name + ""
        global.tokenRecaptcha = envprops.recaptcha_front + ""
        global.Direction_Firebase_DB = envprops.url_firebase
        global.Direction_Back_Login = envprops.url_integration
        global.Direction_Back_panel = envprops.url_back
        global.Direction_LoginOauth = envprops.url_oauth
        global.exam_table_fields = envprops.exam_table_fields
        global.dni_name = envprops.exam_table_fields.dni.name

        global.common_config = envprops.common_config
    }
}
tryset()


const setVariables = (props, callback) => {
    localStorage.envprops = JSON.stringify(props)
    localStorage.siglocanvas = JSON.stringify(false)

    if (props.environment.includes('siglocanvas')) {
        localStorage.siglocanvas = JSON.stringify(true)
    }
    tryset()

    callback()
}

var formdata = new FormData();

let subdomain = window.location.host
if (subdomain.indexOf('localhost') > -1) {
    subdomain = "biv-predev.klarway.com"
}

subdomain = subdomain.split('.')[0]

formdata.append("subdomain", `${subdomain}`);
var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
};
if (global.backurl) {
    fetch("https://bivproctor.work/subdomain", requestOptions)
        .then(response => response.json())
        .then(result => {
            setVariables(result, () => {

            })

        })
        .catch(error => console.log('error', error));
}
else {
    fetch("https://bivproctor.work/subdomain", requestOptions)
        .then(response => response.json())
        .then(result => {
            setVariables(result, () => {
                window.location.reload()
            })

        })
        .catch(error => console.log('error', error));
}