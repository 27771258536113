/**
 * File to serve all function utilities miscelaneous
 *
 * Import:
 * import db from 'utilities/utilities.jsx'
 *
 */

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { jsPDF } from "jspdf";

//import klarlogo from "assets/img/klarlogo.jpg"
//import siglologo from "assets/img/logosiglo.jpg"
import noPhoto from "assets/img/nophoto.png";

import headerkw from "assets/img/headerkw.png";
import footerkw from "assets/img/footerkw.png";

import moment from "moment";
//  import DINPro from "../assets/css/DINPro.ttf"

import { fontWeight } from "@mui/system";
import { DINPro } from "assets/fontsjspdf/DINPro-normal";
import { DINProBold } from "assets/fontsjspdf/DINPro-bold";
import { DINProLight } from "assets/fontsjspdf/DINPro-italic";
import { DINProMedium } from "assets/fontsjspdf/DINPro-bolditalic";

moment.locale("es");
const languages = require("../assets/translate/translate_utilities.json");
let lang = localStorage.lang ? localStorage.lang : "es";

const _anomaliesList = [
  { text: languages[lang].utilities_anotherPersonWasRecognized },
  { text: languages[lang].utilities_lookedAway },
  { text: languages[lang].utilities_moreThanOnePersonWasRecognized },
  { text: languages[lang].utilities_anAlterationInTheEnvironmentIsDetected },
  { text: languages[lang].utilities_tooMuchNoiseInTheEnvironment },
  { text: languages[lang].utilities_lookedDown },
  { text: languages[lang].utilities_lookedUp },
  { text: languages[lang].utilities_lookedToTheSide },
  { text: languages[lang].utilities_youDontHaveEnoughBatteryLevel },
  { text: languages[lang].utilities_activeVirtualMachinesAreFound },
  { text: languages[lang].utilities_youDontHaveEnoughStorageSpace },
  { text: languages[lang].utilities_openProcesses },
  { text: languages[lang].utilities_moreThanOneMonitorIsConnected },
  { text: languages[lang].utilities_theMicrophoneVolumeIsTooLow },
  { text: languages[lang].utilities_imageReplacingCamera },
  { text: languages[lang].utilities_otherToolsAreDetectedInTheForeground },
  { text: languages[lang].utilities_suspiciousKeyboardUsageDetected },
  { text: languages[lang].utilities_thePersonIsNot },
  { text: languages[lang].utilities_theRegisteredPersonIsNotIdentified },
  { text: languages[lang].utilities_faceMovementsAreDetected },
  { text: languages[lang].utilities_recognizeMoreThanOnePerson },
];

const _results = 4;
const _validations = 4;
const _anomalies = _anomaliesList.length;

function elEnter(evt, callback) {
  if (evt.charCode === 13) {
    callback();
  }
}

function getCardsModel() {
  let _model = {};

  _model.totalAnomalies = 0;

  let counters = [];
  for (let i = 0; i < _anomalies; i++) {
    counters.push({
      key: i,
      text: _anomaliesList[i].text,
      quantity: 0,
      percent: 0,
    });
  }
  _model.counters = counters;

  let validations = [];
  for (let i = 0; i < _validations; i++) {
    validations.push({ key: i, quantity: 0 });
  }
  _model.validations = validations;

  //Fill results
  let results = [];
  for (let i = 0; i < _results; i++) {
    results.push({ key: i, quantity: 0 });
  }
  _model.results = results;
  return _model;
}

function fillCardsModel(data) {
  let _model = {};

  let _emptymodel = getCardsModel();

  _model.totalAnomalies = 0;

  if (data.counters[_anomalies] === 0) {
    _model.counters = _emptymodel.counters;
  } else {
    //Fill counters
    let _percentGral = 100 / data.counters[_anomalies];

    _model.totalAnomalies = data.counters[_anomalies];

    let counters = [];

    for (let i = 0; i < _anomalies; i++) {
      counters.push({
        key: i,
        text: _anomaliesList[i].text,
        quantity: data.counters[i],
        percent: data.counters[i] * _percentGral,
      });
    }
    counters = counters.sort((a, b) => b.percent - a.percent);
    for (let i = 0; i < 14; i++) {
      counters[i].key = i;
      counters[i].percent = parseFloat(counters[i].percent).toFixed(0) + "%";
    }
    _model.counters = counters;
  }

  //Fill validations

  let validations = [];

  for (let i = 0; i < _validations; i++) {
    validations.push({ key: i, quantity: data.validations[i] });
  }
  _model.validations = validations;

  //Fill results
  let results = [];
  results.push({ key: 0, quantity: data.finalResultAuditory[1] });
  results.push({ key: 1, quantity: data.finalResultAuditory[0] });
  results.push({ key: 2, quantity: data.finalResultAuditory[2] });
  results.push({ key: 3, quantity: data.finalResultAuditory[3] });

  _model.results = results;

  return _model;
}

function getTitle(cardSelected) {
  const _tableTitleList = [
    { text: languages[lang].utilities_examsWithLowValidation }, // 1
    { text: languages[lang].utilities_examsWithMediumValidation }, // 2
    { text: languages[lang].utilities_examsWithHighValidation }, // 3
    { text: languages[lang].utilities_examsWithCriticValidation }, // 4
    { text: languages[lang].utilities_auditedExamsWithRequestForSanction }, // 6
    { text: languages[lang].utilities_validAuditedExams }, // 5
    { text: languages[lang].utilities_auditedExamsPostponed }, // 7
    { text: languages[lang].utilities_auditedExamsWithCallsForAttention }, // 8
    { text: languages[lang].utilities_detectedAnomalies }, // 9
  ];

  return _tableTitleList[cardSelected - 1].text;
}

function getValidationString(veKey) {
  switch (veKey) {
    case 1:
      return languages[lang].utilities_low;
    case 2:
      return languages[lang].utilities_medium;
    case 3:
      return languages[lang].utilities_high;
    case 4:
      return languages[lang].utilities_critic;
    default:
      return languages[lang].utilities_withoutValidation;
  }
}

function getValidationInteger(veString) {
  switch (veString) {
    case "BAJA":
      return 1;
    case "MEDIA":
      return 2;
    case "ALTA":
      return 3;
    case "CRITICA":
      return 4;
    default:
      return -1;
  }
}

function getFinalResultString(resultKey) {
  switch (resultKey) {
    case 0:
      return languages[lang].utilities_withRequestForSanction;
    case 1:
      return languages[lang].utilities_validated;
    case 2:
      return languages[lang].utilities_deferred;
    case 3:
      return languages[lang].utilities_callOfAttention;
    default:
      return languages[lang].utilities_unaudited;
  }
}

function getFinalResultInteger(resultString) {
  switch (resultString) {
    case "Con solic. de sanción":
      return 0;
    case "Validado":
      return 1;
    case "Aplazado":
      return 2;
    case "Llamado de atención":
      return 3;
    default:
      return null;
  }
}

function getValidationDbString(validationKey) {
  switch (validationKey) {
    case 0:
      return languages[lang].utilities_withoutValidation1;
    case 1:
      return languages[lang].utilities_low1;
    case 2:
      return languages[lang].utilities_medium1;
    case 3:
      return languages[lang].utilities_high1;
    case 4:
      return languages[lang].utilities_critic1;
    default:
      return languages[lang].utilities_n;
  }
}

function getValidationDbInteger(validationString) {
  switch (validationString) {
    case "Sin validación":
      return 6;
    case "Baja":
      return 1;
    case "Media":
      return 2;
    case "Alta":
      return 3;
    case "Crítica":
      return 4;
    case "Sin evidencia":
    case 5:
      return 5;
    default:
      return -1;
  }
}

function getIncidencesCounterExman(data, incidencesCount) {
  let _model = {};

  if (incidencesCount === 0 || data.length === 0) {
    _model.counters = [];
    return _model;
  }

  //Fill counters
  let _percentGral = 100 / incidencesCount;

  var _data = JSON.parse(data);

  let counters = [];
  let countersFinal = [];

  for (let i = 0; i < _anomalies; i++) {
    let _cant =
      _data.incidencesCounters[i] === null ||
      _data.incidencesCounters[i] === undefined
        ? 0
        : _data.incidencesCounters[i];
    counters.push({
      key: i,
      _key: i,
      text: _anomaliesList[i].text,
      quantity: _cant,
      percent: _cant * _percentGral,
    });
  }

  counters = counters.sort((a, b) => b.percent - a.percent);

  for (let i = 0; i < 17; i++) {
    counters[i].key = i;
    if (counters[i].percent !== 0) {
      counters[i].percent = parseFloat(counters[i].percent).toFixed(2) + "%";
      countersFinal.push({
        key: i,
        _key: counters[i]._key,
        text: counters[i].text,
        quantity: counters[i].quantity,
        percent: counters[i].percent,
      });
    }
  }

  _model.counters = countersFinal;

  //Fill validations

  let validations = [];

  for (let i = 0; i > _validations; i++) {
    validations.push({ key: i, quantity: _data.validations[i] });
  }
  _model.validations = validations;

  //Fill results
  let results = [];
  results.push({ key: 0, quantity: 0 });
  results.push({ key: 1, quantity: 0 });
  results.push({ key: 2, quantity: 0 });

  _model.results = results;

  return _model;
}

function exportExamnToPdf(data, uni, funcEnd) {
  getBase64FromUrl(data.instantPhoto, (imgExamn) => {
    data.instantPhoto64 =
      data.instantPhoto && data.instantPhoto.length > 0 ? imgExamn : "";
    getBase64FromUrl(
      data.userData ? JSON.parse(data.userData).i0.i : "",
      (imgProfile) => {
        data.imgProfile64 = imgProfile;
        _exportExamnToPdf(data, uni, funcEnd);
      }
    );
  });
}

export default {
  getCardsModel,
  fillCardsModel,
  getTitle,
  getValidationString,
  getValidationInteger,
  getFinalResultString,
  getFinalResultInteger,
  getValidationDbString,
  getValidationDbInteger,
  getIncidencesCounterExman,
  exportExamnToPdf,
  elEnter,
  checkIfSiglo,
  //  exportarprueba
};

async function obtenerImagenBase642(url) {
  if (url === "") {
    return "";
  }
  try {
    const response = await fetch(url);
    const blob = await response.blob(); // Obtener el archivo como un blob

    // Paso 2: Crear un FileReader para leer el contenido del blob como base64
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    throw new Error("Error al obtener la imagen: " + error.message);
  }
}

async function obtenerImagenBase64(url, fncReturn) {
  if (url === "") {
    return true;
  }
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("get", url);
    xhr.responseType = "blob";

    xhr.onload = async function() {
      var fr = new FileReader();
      fr.onload = async function() {
        resolve(this.result);
      };

      fr.readAsDataURL(xhr.response);
    };

    xhr.send();
  });
}
function getBase64FromUrl(url, fncReturn) {
  if (url === "") {
    fncReturn("");
    return true;
  }

  var xhr = new XMLHttpRequest();
  xhr.open("get", url);
  xhr.responseType = "blob";

  xhr.onload = function() {
    var fr = new FileReader();
    fr.onload = async function() {
      fncReturn(this.result);
    };

    fr.readAsDataURL(xhr.response);
  };

  xhr.send();
}

function getImageDimensions(file) {
  return new Promise(function(resolved, rejected) {
    var i = new Image();
    i.onload = function() {
      resolved({ w: i.width, h: i.height });
    };
    i.src = file;
  });
}

function getDeviceInfo(data) {
  let _deviceInfo = JSON.parse(data.systemReport ? data.systemReport : "{}");

  let _cpu = _deviceInfo.cpu
    ? _deviceInfo.cpu.brand
      ? _deviceInfo.cpu.brand
      : ""
    : "";
  let _cores = _deviceInfo.cpu
    ? _deviceInfo.cpu.cores
      ? _deviceInfo.cpu.cores
      : 1
    : 1;
  let _physicalCores = _deviceInfo.cpu
    ? _deviceInfo.cpu.physicalCores
      ? _deviceInfo.cpu.physicalCores
      : 1
    : 1;

  let _speed = _deviceInfo.cpu
    ? _deviceInfo.cpu.speed
      ? _deviceInfo.cpu.speed
      : 1
    : 1;

  let _mem = _deviceInfo.mem
    ? _deviceInfo.mem.total
      ? _deviceInfo.mem.total
      : 1048576
    : 1048576;

  if (_cpu === "") {
    return languages[lang].utilities_thereIsNoInformation;
  }

  _mem = Math.trunc(_mem / 1024 / 1024) + languages[lang].utilities_mbRam;

  //Mhz
  return (
    "" +
    _cpu +
    " " +
    _cores +
    languages[lang].utilities_nuclei +
    _physicalCores +
    languages[lang].utilities_physicalTo +
    _speed +
    languages[lang].utilities_mhzTo +
    _mem
  );
}

function getInternetInfo(data) {
  let _fin = data.upload_end
    ? " " +
      parseFloat(data.upload_end.replace(" MBps", "")).toFixed(2) +
      " MBps"
    : " -";
  let _inicio = data.upload_init
    ? " " +
      parseFloat(data.upload_init.replace(" MBps", "")).toFixed(2) +
      " MBps"
    : " -";
  let _promedio =
    data.upload_init && data.upload_end
      ? " " +
        (
          (parseFloat(data.upload_init.replace(" MBps", "")) +
            parseFloat(data.upload_end.replace(" MBps", ""))) /
          2
        ).toFixed(2) +
        " MBps"
      : " -";

  //Mhz
  return (
    "Velocidad al inicio del examen: " +
    _inicio +
    "\n" +
    "Velocidad al final del examen: " +
    _fin +
    "\n" +
    "Promedio de velocidad: " +
    _promedio
  );
}

async function checkIfSiglo() {
  const response = await fetch(
    global.Direction_Back_panel + "/v2/checkWorldClass",
    { method: "Post" }
  );
  let j;

  if (response.ok) {
    let json = await response.json();
    j = json.success;
    let cond = false;
    if (j + "" === "1") {
      cond = true;
    }

    localStorage.esSiglo = cond;
  }
}

// Función para agregar texto con manejo de múltiples líneas y ajuste de posición
function addTextWithLineBreakAndPositionAdjustment(
  doc,
  text,
  x,
  y,
  maxWidth,
  lastYPosition,
  fontname,
  fonttype,
  fontsize
) {
  // Dividir el texto en fragmentos que quepan en el ancho máximo
  var fragments = doc.splitTextToSize(text, maxWidth, {});

  // Calcular la altura total ocupada por los fragmentos
  var totalHeight = fragments.length * 0; // 10 es la altura de línea

  // Verificar si el último elemento fue en la misma línea o en una nueva
  if (lastYPosition === y) {
    // Si el último elemento fue en la misma línea, mover el nuevo elemento a la siguiente línea
    y += totalHeight;
  } else {
    // Si el último elemento fue en una nueva línea, posicionar el nuevo elemento debajo del último
    y = lastYPosition + 4 + totalHeight; // 4px de separación entre líneas
  }

  // Agregar los fragmentos uno por uno
  fragments.forEach(function(fragment, index) {
    if (index > 0) {
      // Para fragmentos posteriores, aumentar la posición vertical para la próxima línea
      y += 4; // 10 es la altura de línea
    }
    y = checkPageBreak(doc, y, 0);
    if (fontname) {
      doc.setFont(fontname, fonttype);
      doc.setFontSize(fontsize);
    }
    doc.text(fragment, x, y, { maxWidth: maxWidth });
  });

  // Devolver la nueva posición vertical
  return y;
}

var addedNewPage = false;
function checkPageBreak(doc, y, contentHeight) {
  // Altura máxima del contenido en la página A4
  var pageHeight = 270;

  // Verificar si ya se agregó una nueva página
  if (addedNewPage) {
    // Si ya se agregó una nueva página, devolver la posición vertical actual sin realizar más acciones
    return y;
  }

  // Verificar si la posición vertical actual supera la altura máxima de la página
  if (y > pageHeight) {
    // Agregar encabezado
    doc.addPage();
    doc.addImage(headerkw, "PNG", 0, 0, 210, 0);

    // Agregar pie de página
    let _printDate = moment().format("DD/MM/YYYY HH:mm:ss");

    doc.setFont("DINPro", "normal");
    doc.setFontSize(10);
    doc.text(languages[lang].utilities_poweredByKlarway, 24, 284);

    doc.setFont("DINPro", "normal");
    doc.setFontSize(10);
    doc.text(
      languages[lang].utilities_printingDate + _printDate + ".",
      185,
      284,
      { align: "right" }
    );
    doc.addImage(footerkw, "PNG", 0, 279, 210, 0);

    return 55; // Posición inicial en la nueva página
  } else {
    // Si no es necesario cambiar de página, devolver la posición vertical actual
    return y;
  }
}

async function _exportExamnToPdf(data, uni, fncReturn) {
  const exam_table_fields = global.exam_table_fields;

  const doc = new jsPDF();

  doc.addFileToVFS("DINPro.ttf", DINPro);
  doc.addFont("DINPro.ttf", "DINPro", "normal");

  doc.addFileToVFS("DINProMedium.ttf", DINProMedium);
  doc.addFont("DINProMedium.ttf", "DINPro", "bolditalic");

  doc.addFileToVFS("DINProLight.ttf", DINProLight);
  doc.addFont("DINProLight.ttf", "DINPro", "italic");

  doc.addFileToVFS("DINProBold.ttf", DINProBold);
  doc.addFont("DINProBold.ttf", "DINPro", "bold");

  // };

  let incidencesCounters = [];

  if (data.incidencesCounters !== null) {
    incidencesCounters = JSON.parse(data.incidencesCounters);
  } else {
    incidencesCounters = JSON.parse(
      '{"criticCounters": {"1": 0, "2": 0, "3": 0, "4": 0}, "incidencesCounters": {"0": 0, "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0, "9": 0, "10": 0, "11": 0, "12": 0, "13": 0, "14": 0, "15": 0, "16": 0, "17": 0, "18": 0, "19": 0, "20": 0}}'
    );
  }

  doc.addImage(headerkw, "PNG", 0, 0, 210, 0);

  let _printDate = moment().format("DD/MM/YYYY HH:mm:ss");

  doc.setFont("DINPro", "normal");
  doc.setFontSize(10);
  doc.text(languages[lang].utilities_poweredByKlarway, 24, 284);

  doc.setFont("DINPro", "normal");
  doc.setFontSize(10);
  doc.text(
    languages[lang].utilities_printingDate + _printDate + ".",
    185,
    284,
    { align: "right" }
  );

  doc.addImage(footerkw, "PNG", 0, 279, 210, 0);

  doc.setFont("DINPro", "bold");
  doc.setFontSize(12);
  doc.text("RESUMEN DE MONITOREO", 24, 41);

  doc.setFont("DINPro", "bold");
  doc.setFontSize(10);
  doc.text("Resultado de auditoría:", 24, 50);

  let _resOfAudit = "PENDIENTE";
  if (data.finalResultAuditory !== -1) {
    _resOfAudit =
      data.finalResultAuditory === 1
        ? "VÁLIDO"
        : data.finalResultAuditory === 2
        ? "APLAZADO"
        : data.finalResultAuditory === 0
        ? "SOLICITUD DE SANCIÓN"
        : "PENDIENTE";
  }

  let _resOfAuditColor = "#FF9635";
  if (data.finalResultAuditory !== -1) {
    _resOfAuditColor =
      data.finalResultAuditory === 1
        ? "#009CFB"
        : data.finalResultAuditory === 2
        ? "#DB0000"
        : data.finalResultAuditory === 0
        ? "#DB0000"
        : "#FF9635";
  }

  doc.setFont("DINPro", "bold");
  doc.setFontSize(10);
  doc.setTextColor(_resOfAuditColor);
  doc.text(_resOfAudit, 65, 50);

  doc.setTextColor("#000000");

  //Recuadro Imagen
  doc.setFillColor(220, 220, 220);
  doc.roundedRect(110, 45, 85, 52, 5, 5, "F");

  if (data.imgProfile64 === "") {
    doc.addImage(noPhoto, "PNG", 144, 60, 20, 20);
    console.log("trae imagen???????", data.imgProfile);
    console.log("que trae el data????", data);
  } else {
    var dimenProfile = await getImageDimensions(data.imgProfile64);
    var coordsProfile = calculateAll(dimenProfile, 105, 52, 100, 45);
    // photo, rectWidth, rectHeight, coordLeft, coordTop
    //  85,        52,       117,       134
    doc.addImage(
      data.imgProfile64,
      "JPEG",
      coordsProfile.left,
      coordsProfile.top,
      coordsProfile.widht,
      coordsProfile.height
    );
    console.log("trae imagen???????", data.imgProfile);
    console.log("que trae el data????", data);
  }
  doc.setFont("DINPro", "bold");
  doc.setFontSize(10);
  doc.text("Foto del registro", 111, 102);

  doc.setFont("DINPro", "bold");
  doc.setFontSize(10);
  doc.text("Datos generales", 24, 60);

  // Uso de la función para agregar texto
  var yPosition = 65; // Posición inicial
  var contentHeight = 0;

  // Agregar el primer elemento "Nombre y apellido:"
  doc.setFont("DINPro", "bolditalic");
  doc.setFontSize(10);
  yPosition = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Nombre y apellido:",
    24,
    yPosition,
    40,
    yPosition,
    "DINPro",
    "bolditalic",
    10
  );

  // Agregar el texto de data.userName
  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPosition = addTextWithLineBreakAndPositionAdjustment(
    doc,
    data.userName,
    55,
    yPosition,
    40,
    yPosition,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPosition - 55;

  // Definir la posición del segundo elemento
  var yPositionSecondElement = yPosition + 4; // Añadir separación entre elementos

  // Agregar el segundo elemento "Usuario:"
  doc.setFont("DINPro", "bolditalic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Usuario:",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bolditalic",
    10
  );

  // Agregar el texto de data.user
  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    data.user,
    38,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  yPositionSecondElement = yPositionSecondElement + 4; // Añadir separación entre elementos

  doc.setFont("DINPro", "bolditalic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    global.dni_name + ":",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bolditalic",
    10
  );

  // Agregar el texto de data.user
  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    data.dni + "",
    33,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPosition - (yPosition - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPosition = checkPageBreak(doc, yPosition, contentHeight);

  var yPositionSecondElement = yPositionSecondElement + 4; // Añadir separación entre elementos

  doc.setFont("DINPro", "bolditalic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Materia:",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bolditalic",
    10
  );

  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    data.subjectName,
    41,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  var yPositionSecondElement = yPositionSecondElement + 4; // Añadir separación entre elementos

  doc.setFont("DINPro", "bolditalic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Fecha:",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bolditalic",
    10
  );

  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    data.examStartWithTime || "",
    36,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  var yPositionSecondElement = yPositionSecondElement + 10; // Añadir separación entre elementos

  doc.setFont("DINPro", "bold");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Auditoría",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bold",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  var yPositionSecondElement = yPositionSecondElement + 5; // Añadir separación entre elementos

  doc.setFont("DINPro", "bolditalic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Coincidencia:",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bolditalic",
    10
  );

  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    languages[lang].utilities_yes1,
    46,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  var yPositionSecondElement = yPositionSecondElement + 4; // Añadir separación entre elementos

  doc.setFont("DINPro", "bolditalic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Validación de identidad:",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bolditalic",
    10
  );

  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    languages[lang].utilities_yes,
    63,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  var yPositionSecondElement = yPositionSecondElement + 4; // Añadir separación entre elementos

  doc.setFont("DINPro", "bolditalic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Validación de entorno:",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bolditalic",
    10
  );

  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    languages[lang].utilities_yes,
    61,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  var yPositionSecondElement = yPositionSecondElement + 4; // Añadir separación entre elementos

  doc.setFont("DINPro", "bolditalic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Validación del examen:",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bolditalic",
    10
  );

  let examValidation = () => {
    switch (data.validation) {
      case 0:
        return "Pendiente";
      case 1:
        return "Baja";
      case 2:
        return "Media";
      case 3:
        return "Alta";
      case 4:
        return "Critica";
      case 5:
        return "Sin evidencia";
      case 6:
        return "Procesando";

      default:
        return "";
    }
  };
  //  doc.text(examValidation(), 62, 101)
  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    examValidation(),
    62,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bolditalic",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  let resSesiones = await sesiones(
    doc,
    yPositionSecondElement,
    data,
    incidencesCounters,
    contentHeight
  );
  yPositionSecondElement = resSesiones;

  var yPositionSecondElement = yPositionSecondElement + 10;

  doc.setFont("DINPro", "bold");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Dispositivo utilizado",
    24,
    yPositionSecondElement,
    40,
    yPositionSecondElement,
    "DINPro",
    "bold",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  var yPositionSecondElement = yPositionSecondElement + 5;

  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    getDeviceInfo(data) || "",
    24,
    yPositionSecondElement,
    75,
    yPositionSecondElement,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  var yPositionSecondElement = yPositionSecondElement + 10;

  doc.setFont("DINPro", "bold");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Conexión a Internet (subida):",
    24,
    yPositionSecondElement,
    75,
    yPositionSecondElement,
    "DINPro",
    "bold",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  var yPositionSecondElement = yPositionSecondElement + 5;

  doc.setFont("DINPro", "italic");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    getInternetInfo(data) || "",
    24,
    yPositionSecondElement,
    75,
    yPositionSecondElement,
    "DINPro",
    "italic",
    10
  );

  contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
  // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
  var yPositionSecondElement = yPositionSecondElement + 10;
  yPositionSecondElement = checkPageBreak(
    doc,
    yPositionSecondElement,
    contentHeight
  );

  doc.setFont("DINPro", "bold");
  doc.setFontSize(10);
  yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
    doc,
    "Modalidad ",
    24,
    yPositionSecondElement,
    75,
    yPositionSecondElement,
    "DINPro",
    "bold",
    10
  );

  if (
    data.finalResultAuditory &&
    (!data.auditorUser || data.auditorUser == "-") &&
    data.finalResultAuditory > -1
  ) {
    //  doc.setFont("DINPro", "bold")
    //  doc.setFontSize(10)
    //  doc.text("AUTOMÁTICA", 47, 41)

    doc.setFont("DINPro", "bold");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "AUTOMÁTICA",
      47,
      yPositionSecondElement,
      75,
      yPositionSecondElement,
      "DINPro",
      "bold",
      10
    );

    contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
    // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
    yPositionSecondElement = checkPageBreak(
      doc,
      yPositionSecondElement,
      contentHeight
    );

    var yPositionSecondElement = yPositionSecondElement + 7;

    doc.setTextColor("#000000");

    contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
    // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
    yPositionSecondElement = checkPageBreak(
      doc,
      yPositionSecondElement,
      contentHeight
    );

    var yPositionSecondElement = yPositionSecondElement + 5;

    doc.setFont("DINPro", "bolditalic");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "Detalle:",
      24,
      yPositionSecondElement,
      75,
      yPositionSecondElement,
      "DINPro",
      "bolditalic",
      10
    );

    doc.setFont("DINPro", "italic");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "No contiene detalle",
      40,
      yPositionSecondElement,
      140,
      yPositionSecondElement,
      "DINPro",
      "italic",
      10
    );

    contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
    // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
    yPositionSecondElement = checkPageBreak(
      doc,
      yPositionSecondElement,
      contentHeight
    );
  } else {
    //  doc.setFont("DINPro", "bold")
    //  doc.setFontSize(10)
    //  doc.text("MANUAL", 47, 41)

    doc.setFont("DINPro", "bold");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "MANUAL",
      47,
      yPositionSecondElement,
      75,
      yPositionSecondElement,
      "DINPro",
      "bold",
      10
    );

    contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
    // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
    yPositionSecondElement = checkPageBreak(
      doc,
      yPositionSecondElement,
      contentHeight
    );

    var yPositionSecondElement = yPositionSecondElement + 7;

    doc.setTextColor("#000000");
    doc.setFont("DINPro", "bolditalic");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "Auditor:",
      24,
      yPositionSecondElement,
      75,
      yPositionSecondElement,
      "DINPro",
      "bolditalic",
      10
    );

    doc.setFont("DINPro", "italic");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      data.auditorUser ? data.auditorUser : "",
      40,
      yPositionSecondElement,
      75,
      yPositionSecondElement,
      "DINPro",
      "italic",
      10
    );

    contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
    // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
    yPositionSecondElement = checkPageBreak(
      doc,
      yPositionSecondElement,
      contentHeight
    );

    var yPositionSecondElement = yPositionSecondElement + 5;

    //  doc.setFont("DINPro", "bolditalic")
    //  doc.setFontSize(10)
    //  doc.text("Duración:", 24, 58)
    //  doc.setFont("DINPro", "italic")
    //  doc.setFontSize(10)

    doc.setFont("DINPro", "bolditalic");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "Duración:",
      24,
      yPositionSecondElement,
      75,
      yPositionSecondElement,
      "DINPro",
      "bolditalic",
      10
    );

    let _durationAuditory = "";
    if (
      data.startOfAudit &&
      data.startOfAudit !== null &&
      data.endOfAudit &&
      data.endOfAudit !== null
    ) {
      let _start = moment(data.startOfAudit);
      let _end = moment(data.endOfAudit);
      let _diff = _end.diff(_start);
      _durationAuditory = moment.utc(_diff).format("HH:mm:ss");
    }

    //  doc.text( _durationAuditory, 44, 58)
    doc.setFont("DINPro", "italic");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      _durationAuditory,
      44,
      yPositionSecondElement,
      75,
      yPositionSecondElement,
      "DINPro",
      "italic",
      10
    );

    contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
    // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
    yPositionSecondElement = checkPageBreak(
      doc,
      yPositionSecondElement,
      contentHeight
    );

    var yPositionSecondElement = yPositionSecondElement + 5;

    doc.setFont("DINPro", "bolditalic");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "Detalle:",
      24,
      yPositionSecondElement,
      75,
      yPositionSecondElement,
      "DINPro",
      "bolditalic",
      10
    );

    doc.setFont("DINPro", "italic");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      data.finalResultAuditoryDetail ? data.finalResultAuditoryDetail : "",
      40,
      yPositionSecondElement,
      140,
      yPositionSecondElement,
      "DINPro",
      "italic",
      10
    );

    contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
    // Llamar a checkPageBreak para verificar si es necesario cambiar de página después de cada elemento agregado
    yPositionSecondElement = checkPageBreak(
      doc,
      yPositionSecondElement,
      contentHeight
    );
  }

  var blob = doc.output("blob");
  var blobUrl = URL.createObjectURL(blob);

  // Abre una nueva pestaña con la URL del Blob
  window.open(blobUrl, "_blank");
  fncReturn();
}

async function sesiones(
  doc,
  yPositionSecondElement,
  data,
  incidencesCounters,
  contentHeight,
  uni
) {
  let fnCurrentSesion = async () => {
    yPositionSecondElement += 10;

    let currentData = data.actual_attempt;

    var textWidth = 66; // Obtener el ancho del texto
    var textHeight = 6; // Altura del fondo del texto
    var x = 24;

    doc.setFont("DINPro", "bold");
    doc.setFontSize(10);
    doc.setFillColor(235, 235, 235);
    doc.roundedRect(
      x - 2,
      yPositionSecondElement - textHeight + 2,
      textWidth + 15,
      textHeight,
      1,
      1,
      "F"
    ); // Dibujar el rectángulo de fondo
    let imgPosition = yPositionSecondElement;
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "Sesión actual",
      24,
      yPositionSecondElement,
      40,
      yPositionSecondElement,
      "DINPro",
      "bold",
      10
    );

    doc.setFont("DINPro", "italic");
    doc.setFontSize(8);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "Inicio:",
      48,
      yPositionSecondElement,
      40,
      yPositionSecondElement,
      "DINPro",
      "italic",
      9
    );

    doc.setFont("DINPro", "italic");
    doc.setFontSize(8);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      currentData.createdAtTime || "",
      58,
      yPositionSecondElement,
      40,
      yPositionSecondElement,
      "DINPro",
      "italic",
      9
    );

    yPositionSecondElement += 10; // Añadir separación entre elementos

    let textValidation2;
    switch (currentData.ve) {
      case 1:
        textValidation2 = "Baja";
        break;
      case 2:
        textValidation2 = "Media";
        break;
      case 3:
        textValidation2 = "Alta";
        break;
      case 4:
        textValidation2 = "Crítica";
        break;

      default:
        textValidation2 = "Sin validación";
        break;
    }

    doc.setFont("DINPro", "bold");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "Validación de sesión:",
      24,
      yPositionSecondElement,
      40,
      yPositionSecondElement,
      "DINPro",
      "bold",
      10
    );

    doc.setFont("DINPro", "bold");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      textValidation2,
      60,
      yPositionSecondElement,
      40,
      yPositionSecondElement,
      "DINPro",
      "bold",
      10
    );

    yPositionSecondElement += 10; // Añadir separación entre elementos

    doc.setFont("DINPro", "bold");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "Anomalías",
      24,
      yPositionSecondElement,
      40,
      yPositionSecondElement,
      "DINPro",
      "bold",
      10
    );

    contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
    yPositionSecondElement = checkPageBreak(
      doc,
      yPositionSecondElement,
      contentHeight
    );

    yPositionSecondElement += 5; // Añadir separación entre elementos
    if (typeof currentData.incidencesCounters === "string") {
      currentData.incidencesCounters = JSON.parse(
        currentData.incidencesCounters
      );
    }
    let _bajas = currentData.incidencesCounters?.criticCounters?.[1] || 0;
    let _medias = currentData.incidencesCounters?.criticCounters?.[2] || 0;
    let _altas = currentData.incidencesCounters?.criticCounters?.[3] || 0;
    let _criticas = currentData.incidencesCounters?.criticCounters?.[4] || 0;

    if (uni !== "1" && uni !== "ST") {
      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "Bajas:",
        24,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        _bajas.toString() || "",
        35,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "/",
        40,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "Medias:",
        44,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        _medias.toString() || "",
        57,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "/",
        62,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "Altas:",
        66,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        _altas.toString() || "",
        76,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "/",
        81,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "Críticas:",
        85,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      doc.setFont("DINPro", "bolditalic");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        _criticas.toString() || "",
        99,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bolditalic",
        10
      );

      contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
      yPositionSecondElement = checkPageBreak(
        doc,
        yPositionSecondElement,
        contentHeight
      );
    }

    doc.setFillColor(220, 220, 220);
    doc.roundedRect(110, imgPosition, 80, 47, 5, 5, "F");
    if (currentData.instantPhoto?.length > 0) {
      let url64 = await obtenerImagenBase64(currentData.instantPhoto);
      console.log("debería estar el base64", url64);
      currentData.instantPhoto64 = url64;
    }

    if (currentData.instantPhoto64?.length > 0) {
      var dimenInstant = await getImageDimensions(currentData.instantPhoto64);
      var coordsInstant = calculateAll(dimenInstant, 99, 47, 100, imgPosition);
      doc.addImage(
        currentData.instantPhoto64,
        "PNG",
        coordsInstant.left,
        coordsInstant.top,
        coordsInstant.widht,
        coordsInstant.height
      );
    } else {
      doc.addImage(noPhoto, "PNG", 140, imgPosition + 12, 20, 20);
    }

    doc.setFont("DINPro", "bold");
    doc.setFontSize(10);
    doc.text("Foto de validación - Sesión actual", 111, imgPosition + 50);

    yPositionSecondElement += 10; // Añadir separación entre elementos

    doc.setFont("DINPro", "bold");
    doc.setFontSize(10);
    yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
      doc,
      "Detalle de anomalías",
      24,
      yPositionSecondElement,
      40,
      yPositionSecondElement,
      "DINPro",
      "bold",
      10
    );

    contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
    yPositionSecondElement = checkPageBreak(
      doc,
      yPositionSecondElement,
      contentHeight
    );

    if (uni !== "1" && uni !== "ST") {
      if (data.incidencesTable.counters.length > 0) {
        for (let i = 0; i < data.incidencesTable.counters.length; i++) {
          var _porc = data.incidencesTable.counters[i].percent.toString();
          yPositionSecondElement += 5;

          doc.setFont("DINPro", "italic");
          doc.setFontSize(10);
          yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
            doc,
            _porc || "",
            24,
            yPositionSecondElement,
            40,
            yPositionSecondElement,
            "DINPro",
            "italic",
            10
          );

          doc.setFont("DINPro", "italic");
          doc.setFontSize(10);
          yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
            doc,
            data.incidencesTable.counters[i].text || "",
            40,
            yPositionSecondElement,
            40,
            yPositionSecondElement,
            "DINPro",
            "italic",
            10
          );

          contentHeight +=
            yPositionSecondElement - (yPositionSecondElement - 10);
          yPositionSecondElement = checkPageBreak(
            doc,
            yPositionSecondElement,
            contentHeight,
            1
          );
        }
      } else {
        yPositionSecondElement += 5;

        doc.setFont("DINPro", "italic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          "-",
          24,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "italic",
          10
        );

        contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
        yPositionSecondElement = checkPageBreak(
          doc,
          yPositionSecondElement,
          contentHeight
        );
      }
    }
  };

  await fnCurrentSesion();
  if (data.previous_attempts !== null)
    for (let i = 0; i < data.previous_attempts.length; i++) {
      let dataAttempt = data.previous_attempts[i];

      //si entra foto, escribo la sesion si no salto de pagina

      yPositionSecondElement += 15; // Añadir separación entre elementos

      if (yPositionSecondElement + 80 >= 300) {
        addedNewPage = false;
        yPositionSecondElement = await checkPageBreak(doc, 300);
      }

      var textWidth = 66; // Obtener el ancho del texto
      var textHeight = 6; // Altura del fondo del texto
      var x = 24;

      doc.setFont("DINPro", "bold");
      doc.setFontSize(10);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(
        x - 2,
        yPositionSecondElement - textHeight + 2,
        textWidth + 15,
        textHeight,
        1,
        1,
        "F"
      ); // Dibujar el rectángulo de fondo
      let imgPosision = yPositionSecondElement;
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "Sesión " + (i + 1),
        24,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bold",
        10
      );

      doc.setFont("DINPro", "italic");
      doc.setFontSize(8);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "Inicio:",
        48,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "italic",
        9
      );

      doc.setFont("DINPro", "italic");
      doc.setFontSize(8);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        dataAttempt.createdAtTime || "",
        58,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "italic",
        9
      );

      yPositionSecondElement += 10; // Añadir separación entre elementos

      doc.setFont("DINPro", "bold");
      doc.setFontSize(10);

      let textValidation;

      switch (dataAttempt.ve) {
        case 1:
          textValidation = "Baja";
          break;
        case 2:
          textValidation = "Media";
          break;
        case 3:
          textValidation = "Alta";
          break;
        case 4:
          textValidation = "Crítica";
          break;

        default:
          textValidation = "Sin validación";
          break;
      }
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "Validación de sesión:",
        24,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bold",
        10
      );

      doc.setFont("DINPro", "bold");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        textValidation,
        60,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bold",
        10
      );
      yPositionSecondElement += 10; // Añadir separación entre elementos

      doc.setFont("DINPro", "bold");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "Anomalías",
        24,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bold",
        10
      );

      contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
      yPositionSecondElement = checkPageBreak(
        doc,
        yPositionSecondElement,
        contentHeight
      );

      yPositionSecondElement += 5; // Añadir separación entre elementos
      if (typeof dataAttempt.incidencesCounters === "string") {
        dataAttempt.incidencesCounters = JSON.parse(
          dataAttempt.incidencesCounters
        );
      }
      let _bajas = dataAttempt.incidencesCounters?.criticCounters?.[1] || 0;
      let _medias = dataAttempt.incidencesCounters?.criticCounters?.[2] || 0;
      let _altas = dataAttempt.incidencesCounters?.criticCounters?.[3] || 0;
      let _criticas = dataAttempt.incidencesCounters?.criticCounters?.[4] || 0;

      if (uni !== "1" && uni !== "ST") {
        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          "Bajas:",
          24,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          _bajas.toString() || "",
          35,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          "/",
          40,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          "Medias:",
          44,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          _medias.toString() || "",
          57,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          "/",
          62,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          "Altas:",
          66,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          _altas.toString() || "",
          76,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          "/",
          81,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          "Críticas:",
          85,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        doc.setFont("DINPro", "bolditalic");
        doc.setFontSize(10);
        yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
          doc,
          _criticas.toString() || "",
          99,
          yPositionSecondElement,
          40,
          yPositionSecondElement,
          "DINPro",
          "bolditalic",
          10
        );

        contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
        yPositionSecondElement = checkPageBreak(
          doc,
          yPositionSecondElement,
          contentHeight
        );
      }

      doc.setFillColor(220, 220, 220);
      doc.roundedRect(110, imgPosision, 80, 47, 5, 5, "F");
      if (dataAttempt.instantPhoto?.length > 0) {
        let url64 = await obtenerImagenBase64(dataAttempt.instantPhoto);
        dataAttempt.instantPhoto64 = url64;
      }
      if (dataAttempt.instantPhoto64?.length > 0) {
        var dimenInstant = await getImageDimensions(dataAttempt.instantPhoto64);
        var coordsInstant = calculateAll(
          dimenInstant,
          99,
          47,
          100,
          imgPosision
        );
        doc.addImage(
          dataAttempt.instantPhoto64,
          "PNG",
          coordsInstant.left,
          coordsInstant.top,
          coordsInstant.widht,
          coordsInstant.height
        );
      } else {
        doc.addImage(noPhoto, "PNG", 140, imgPosision + 12, 20, 20);
      }

      doc.setFont("DINPro", "bold");
      doc.setFontSize(10);
      doc.text("Foto de validación - Sesión " + (i + 1), 111, imgPosision + 50);

      yPositionSecondElement += 10; // Añadir separación entre elementos

      doc.setFont("DINPro", "bold");
      doc.setFontSize(10);
      yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
        doc,
        "Detalle de anomalías",
        24,
        yPositionSecondElement,
        40,
        yPositionSecondElement,
        "DINPro",
        "bold",
        10
      );

      contentHeight += yPositionSecondElement - (yPositionSecondElement - 10);
      yPositionSecondElement = checkPageBreak(
        doc,
        yPositionSecondElement,
        contentHeight
      );

      if (uni !== "1" && uni !== "ST") {
        if (dataAttempt.incidencesTable.counters.length > 0) {
          for (
            let i = 0;
            i < dataAttempt.incidencesTable.counters.length;
            i++
          ) {
            var _porc = dataAttempt.incidencesTable.counters[
              i
            ].percent.toString();
            yPositionSecondElement += 5;

            doc.setFont("DINPro", "italic");
            doc.setFontSize(10);
            yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
              doc,
              _porc || "",
              24,
              yPositionSecondElement,
              40,
              yPositionSecondElement,
              "DINPro",
              "italic",
              10
            );

            doc.setFont("DINPro", "italic");
            doc.setFontSize(10);
            yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
              doc,
              dataAttempt.incidencesTable.counters[i].text || "",
              40,
              yPositionSecondElement,
              40,
              yPositionSecondElement,
              "DINPro",
              "italic",
              10
            );

            contentHeight +=
              yPositionSecondElement - (yPositionSecondElement - 10);
            yPositionSecondElement = checkPageBreak(
              doc,
              yPositionSecondElement,
              contentHeight,
              1
            );
          }
        } else {
          yPositionSecondElement += 5;

          doc.setFont("DINPro", "italic");
          doc.setFontSize(10);
          yPositionSecondElement = addTextWithLineBreakAndPositionAdjustment(
            doc,
            "-",
            24,
            yPositionSecondElement,
            40,
            yPositionSecondElement,
            "DINPro",
            "italic",
            10
          );

          contentHeight +=
            yPositionSecondElement - (yPositionSecondElement - 10);
          yPositionSecondElement = checkPageBreak(
            doc,
            yPositionSecondElement,
            contentHeight
          );
        }
      }
    }
  else {
  }

  return yPositionSecondElement; // Return the updated position
}

function calculateAll(photo, rectWidth, rectHeight, coordLeft, coordTop) {
  //doc.addImage(data.imgProfile64, 'JPEG', coords.left, coords.top, coords.widht, coords.height)
  //var coords = calculateAll(dimensions, 32, 64, 13, 51)

  let _coords = [];
  var prop = 0;

  if (photo.w > photo.h) {
    prop = (100 * rectWidth) / photo.w;
    prop = prop / 100;
    _coords.widht = photo.w * prop;
    _coords.height = photo.h * prop;
    if (_coords.height > rectHeight) {
      prop = (100 * rectHeight) / photo.h;
      prop = prop / 100;
      _coords.widht = photo.w * prop;
      _coords.height = photo.h * prop;
    }
  } else {
    prop = (100 * rectHeight) / photo.h;
    prop = prop / 100;
    _coords.widht = photo.w * prop;
    _coords.height = photo.h * prop;
    if (_coords.widht > rectWidth) {
      prop = (100 * rectWidth) / photo.w;
      prop = prop / 100;
      _coords.widht = photo.w * prop;
      _coords.height = photo.h * prop;
    }
  }
  //prop = prop / 100
  //_coords.widht = photo.w * prop
  //_coords.height = photo.h * prop
  _coords.left = (rectWidth - _coords.widht) / 2 + coordLeft;
  _coords.top = (rectHeight - _coords.height) / 2 + coordTop;
  return _coords;
}
