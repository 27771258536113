import React from 'react'

import moment from 'moment'
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import Button from "components/CustomButtons/Button.jsx"

import KWValidationFormTable from "components/KWValidationFormTable/KWValidationFormTable.jsx"

import { useRef } from 'react'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

moment.locale('es')

const languages = require('../../assets/translate/translate_validationVideo&infoPC&auditResult.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

function KWValidationFormVideo(props) {

  const {
    data,
    _data,
    //dataForTable,
    incidencesTableScroll,
    changeIncidence,
    incidenceSelected,
  } = props

  KWValidationFormVideo.defaultProps = {
    data: [{ incidences: [], incidencesFiltered: { incidenceLow: [], incidenceMedium: [], incidenceHigh: [], incidenceCritic: [] } }],
    //dataForTable: [],
    incidencesTableScroll: true,
    changeIncidence: () => { },
    incidenceSelected: "",
  }

  let _incidencesCounters = []

  //console.log("data")
  //console.log(data)

  if (data.incidencesCounters !== undefined) {
    _incidencesCounters = data.incidencesCounters ? JSON.parse(data.incidencesCounters) : null
    if (_incidencesCounters === null) {
      _incidencesCounters = JSON.parse('{"criticCounters": {"1": 0, "2": 0, "3": 0, "4": 0}, "incidencesCounters": {"0": 0, "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0, "9": 0, "10": 0, "11": 0, "12": 0, "13": 0, "14": 0, "15": 0, "16": 0}}')
    }
  } else {
    _incidencesCounters = JSON.parse('{"criticCounters": {"1": 0, "2": 0, "3": 0, "4": 0}, "incidencesCounters": {"0": 0, "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0, "9": 0, "10": 0, "11": 0, "12": 0, "13": 0, "14": 0, "15": 0, "16": 0}}')
  }

  const _baja = _incidencesCounters.criticCounters[1] ? _incidencesCounters.criticCounters[1] : 0
  const _media = _incidencesCounters.criticCounters[2] ? _incidencesCounters.criticCounters[2] : 0
  const _alta = _incidencesCounters.criticCounters[3] ? _incidencesCounters.criticCounters[3] : 0
  const _critica = _incidencesCounters.criticCounters[4] ? _incidencesCounters.criticCounters[4] : 0

  const myRef = useRef(null)


  const executeScroll = () => myRef.current.scrollIntoView()
  return (

    <GridContainer style={{ marginTop: 0, paddingLeft: 10 }}>

      {JSON.parse(localStorage.siglocanvas) ? null :

        <GridItem xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginBottom: 5, paddingBottom: 5 }}>
          <GridContainer style={{ marginTop: 0, paddingLeft: 10 }}>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <div ref={myRef} style={{ paddingLeft: 5 }}>{languages[lang].KWValidationFormVideo_screenVideo}</div>
            </GridItem>
            {data.desktopVideo != null ?
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: 450, display: "flex", alignItems: 'center', justifyContent: 'flex-start' }}>
                <video key={data.desktopVideo} style={{ paddingLeft: 5, maxHeight: 400, marginTop: 10 }} id='videoDesktop' controls preload="auto"
                  onPlay={(x) => {
                    let _facevideo = document.getElementById('video')
                    let desk = document.getElementById('videoDesktop')
                    if (desk.paused || desk.ended) {
                      desk.pause()
                      if (data.faceVideo != null) {
                        _facevideo.play()
                      }
                    } else {
                      desk.play()
                      if (data.faceVideo != null) {
                        _facevideo.pause()
                      }
                    }
                    return
                  }}
                  onTimeUpdate={(x) => {
                    let valor = x.currentTarget.currentTime
                    let desk = document.getElementById('videoDesktop')
                    let _facevideo = document.getElementById('video')
                    if (!desk.paused) {
                      if (document.getElementById('video')) {
                        if (data.faceVideo != null) {
                          _facevideo.currentTime = valor
                        }
                      }
                    }
                  }}
                >
                  <source src={data.desktopVideo ? JSON.parse(data.desktopVideo) : null} type="video/webm" />
                  {languages[lang].KWValidationFormVideo_yourBDNSHTML5Video}
                </video>
              </GridItem>
              :
              data.faceVideo != null ?
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: 450, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ height: 400, paddingRight: 20, marginTop: 10, width: "90%", display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: '#f7f7f7', fontSize: 18, fontWeight: 400, color: '#8c8a88' }}>No tiene</div>
                </GridItem>
                :
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: 200, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ height: 150, width: "90%", display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: '#f7f7f7', fontSize: 18, fontWeight: 400, color: '#8c8a88' }}>No tiene</div>
                </GridItem>
            }
          </GridContainer>
        </GridItem>
      }
      {JSON.parse(localStorage.siglocanvas) ? null :

        <GridItem xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginBottom: 5, paddingBottom: 5 }}>
          <GridContainer style={{ marginTop: 0, paddingLeft: 10 }}>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <div style={{ width: "100%", display: "flex", justifyContent: 'flex-end', paddingRight: 20 }}>{languages[lang].KWValidationFormVideo_cameraVAAudio}</div>
            </GridItem>
            {data.faceVideo != null ?
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: 450, display: "flex", alignItems: 'center', justifyContent: 'flex-end' }}>
                <video key={data.faceVideo} style={{ paddingRight: 20, maxHeight: 400, marginTop: 10 }} id='video' controls preload="auto"
                  onPlay={(x) => {
                    let _facevideo = document.getElementById('video')
                    let desk = document.getElementById('videoDesktop')
                    if (_facevideo.paused || _facevideo.ended) {
                      _facevideo.pause()
                      if (data.desktopVideo != null) {
                        desk.play()
                      }
                    } else {
                      _facevideo.play()
                      if (data.desktopVideo != null) {
                        desk.pause()
                      }
                    }
                    return
                  }}
                  onTimeUpdate={(x) => {
                    let valor = x.currentTarget.currentTime
                    let desk = document.getElementById('videoDesktop')
                    let _facevideo = document.getElementById('video')
                    if (!_facevideo.paused) {
                      if (document.getElementById('videoDesktop')) {
                        if (data.desktopVideo != null) {
                          desk.currentTime = valor
                        }
                      }
                    }
                  }}
                >
                  <source src={data.faceVideo ? JSON.parse(data.faceVideo) : null} type="video/webm" />
                  {languages[lang].KWValidationFormVideo_yourBDNSHTML5Video}
                </video>
              </GridItem>
              :
              data.desktopVideo != null ?
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: 450, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ height: 400, paddingRight: 20, marginTop: 10, width: "90%", display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: '#f7f7f7', fontSize: 18, fontWeight: 400, color: '#8c8a88' }}>No tiene</div>
                </GridItem>
                :
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: 200, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ height: 150, width: "90%", display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: '#f7f7f7', fontSize: 18, fontWeight: 400, color: '#8c8a88' }}>No tiene</div>
                </GridItem>
            }
          </GridContainer>
        </GridItem>
      }
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20, marginBottom: 20, display: "flex", justifyContent: "center" }}>
        <div style={{ width: "99.3%", height: 5, borderTop: '1px solid #ced4db' }} />
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", flexDirection: "row", marginTop: 0, marginBottom: 20 }}>
        <GridItem xs={6} sm={6} md={3} lg={3} xl={3} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button
            style={{ width: "80%" }}
            color={(incidenceSelected === "Baja" || incidenceSelected === "") ? "greenBiv" : 'greyKW'}
            //color={(incidenceSelected === languages[lang].KWValidationFormVideo_low1 || incidenceSelected === "") ? "greenBiv" : 'greyKW'}
            round
            onClick={() => {
              (incidenceSelected === "Baja") ? changeIncidence("") : changeIncidence("Baja")
            }}
          > {languages[lang].KWValidationFormVideo_low + _baja} </Button>
        </GridItem>

        <GridItem xs={6} sm={6} md={3} lg={3} xl={3} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button
            style={{ width: "80%" }}
            // color={(incidenceSelected === languages[lang].KWValidationFormVideo_medium1 || incidenceSelected === "") ? "yellowBiv" : 'greyKW'}
            color={(incidenceSelected === "Media" || incidenceSelected === "") ? "yellowBiv" : 'greyKW'}
            round
            onClick={() => {
              (incidenceSelected === "Media") ? changeIncidence("") : changeIncidence("Media")
            }}
          > {languages[lang].KWValidationFormVideo_medium + _media} </Button>
        </GridItem>

        <GridItem xs={6} sm={6} md={3} lg={3} xl={3} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button
            style={{ width: "80%" }}
            // color={(incidenceSelected === languages[lang].KWValidationFormVideo_high1 || incidenceSelected === "") ? "orangeBiv" : 'greyKW'}
            color={(incidenceSelected === "Alta" || incidenceSelected === "") ? "orangeBiv" : 'greyKW'}
            round
            onClick={() => {
              (incidenceSelected === "Alta") ? changeIncidence("") : changeIncidence("Alta")
            }}
          > {languages[lang].KWValidationFormVideo_high + _alta} </Button>
        </GridItem>

        <GridItem xs={6} sm={6} md={3} lg={3} xl={3} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button
            style={{ width: "80%" }}
            color={(incidenceSelected === "Crítica" || incidenceSelected === "") ? "redBiv" : 'greyKW'}
            //color={(incidenceSelected === languages[lang].KWValidationFormVideo_critic1 || incidenceSelected === "") ? "redBiv" : 'greyKW'}
            round
            onClick={() => {
              (incidenceSelected === "Crítica") ? changeIncidence("") : changeIncidence("Crítica")
            }}
          > {languages[lang].KWValidationFormVideo_critic + _critica} </Button>
        </GridItem>
      </GridItem>



      <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 10 }}>

        <KWValidationFormTable
          //incidencesToExamine={dataForTable}
          incidencesToExamine={data.incidences ? (incidenceSelected === "") ? data.incidences : (incidenceSelected === "Baja") ? data.incidencesFiltered.incidenceLow : (incidenceSelected === "Media") ? data.incidencesFiltered.incidenceMedium : (incidenceSelected === "Alta") ? data.incidencesFiltered.incidenceHigh : data.incidencesFiltered.incidenceCritic : []}
          incidencesCounters={_incidencesCounters}
          incidenceSelected={incidenceSelected}
          scrolleable={incidencesTableScroll}
          videoGoButton={(x) => {
            // document.getElementById('info-slide-description').scrollTop = 100
            if (JSON.parse(localStorage.siglocanvas)) { return true }

            executeScroll()
            let time = x / 1000
            if (document.getElementById('video')) {
              let face = document.getElementById('video')
              if (data.faceVideo != null) {
                face.currentTime = time > 2 ? time - 2 : 0
              }
            }
            if (document.getElementById('videoDesktop')) {
              let desk = document.getElementById('videoDesktop')
              if (data.desktopVideo != null) {
                desk.currentTime = time > 2 ? time - 2 : 0
              }
            }
          }}
        />
        {/* } */}

      </GridItem>

    </GridContainer>
  )

}

export default KWValidationFormVideo